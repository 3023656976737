<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          striped
          hover
          responsive
          :items="list"
          :fields="columns"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(image_url)="data">
            <div class="d-flex justify-content-center">
              <a
                href="#"
                :id="`hover-image-${tabIndex}-${data.item.id}`"
                class="w-100"
              >
                <b-img
                  class="box-image1-1"
                  :src="data.item.image_url || '/img/default-placeholder.png'"
                  fluid
                  @error="handleImageSrc"
                ></b-img>
              </a>

              <b-popover
                :target="`hover-image-${tabIndex}-${data.item.id}`"
                triggers="focus hover"
                placement="righttop"
                fallback-placement="clockwise"
                delay="200"
                custom-class="hover-image-popover"
              >
                <b-img
                  class="hover-preview"
                  :src="data.item.image_url || '/img/default-placeholder.png'"
                  fluid
                  @error="handleImageSrc"
                ></b-img>
              </b-popover>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <div class="text-center">
              <router-link :to="'/report/customer/' + data.item.user_guid">
                <b-button variant="link" class="name-link p-0 text-left">
                  {{ data.item.member_id }}
                </b-button>
              </router-link>
              <div class="line-clamp2 description">
                {{ data.item.customer_name }}
              </div>
            </div>
          </template>
          <template v-slot:cell(allow_customer_redeem)="data">
            <div>
              <template v-if="data.item.invoice_no">
                <router-link
                  :to="'/report/transaction/' + data.item.transaction_id"
                >
                  <b-button variant="link" class="name-link p-0">
                    {{ data.item.invoice_no }}
                  </b-button>
                </router-link>
              </template>
              <div v-else>
                {{ "-" }}
              </div>
              <div class="description">{{ data.item.branch_name }}</div>
            </div>
          </template>
          <template v-slot:cell(created_time)="data">
            <div class="text-center">
              <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.created_time | moment("HH:mm:ss") }}
              </div>
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <span
                  :class="[
                    data.item.status == 4
                      ? 'text-danger'
                      : data.item.status == 3
                      ? 'text-success'
                      : 'text-warning',
                  ]"
                  >{{ data.item.status_name }}</span
                ><br />
                <span
                  v-if="data.item.status == 4"
                  class="cursor-pointer"
                  @click="changeStatus(data.item.id)"
                >
                  <u><small> เปลี่ยนสถานะรอตรวจสอบจากเจ้าหน้า</small></u>
                </span>
              </div>
              <font-awesome-icon
                v-if="data.item.status == 4"
                icon="exclamation-circle"
                class="ml-2 f-16"
                v-b-tooltip="{
                  title: data.item.error_detail,
                  placement: 'right',
                  variant: 'warning',
                }"
              />
            </div>
          </template>
          <template v-slot:cell(action)="data">
            <div class="d-flex justify-content-center">
              <router-link
                v-if="[1, 2].includes(data.item.status)"
                :to="`/setting/receipt/monitor/${id}/detail/` + data.item.id"
              >
                <b-button variant="link" class="text-black px-1 py-0">
                  <u>Detail</u>
                </b-button>
              </router-link>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <ModalPreviewImage ref="modalPreviewImage" />
  </div>
</template>

<script>
import ModalPreviewImage from "@/components/modal/ModalPreviewImage";

export default {
  components: {
    ModalPreviewImage,
  },
  props: {
    list: {
      required: true,
      type: Array,
    },
    columns: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: false,
      type: Boolean,
    },
    id: {
      required: false,
      type: [String, Number],
    },
    tabIndex: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {};
  },
  methods: {
    showImage(imageUrl) {
      this.$refs.modalPreviewImage.show(imageUrl);
    },
    async changeStatus(id) {
      try {
        this.$bus.$emit("showLoading");
        const result = await this.axios.put(
          `/ReceiptScanner/update/status/waitforadmin/${id}`
        );
        this.$bus.$emit("hideLoading");
        if (result.data.result == 1) {
          this.successAlert();
          this.$emit("getList");
        } else this.errorAlert(result.data.message);
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: #f0b6c7;
  color: var(--font-color);
}

.time {
  color: #afafaf;
}

.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.badge {
  font-size: 85%;
  background-color: var(--theme-secondary-color);
  color: var(--primary-color);
  font-weight: 100;

  max-width: max-content;
}

.description {
  color: #b1b1b1;
}

.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.box-image1-1 {
  cursor: zoom-in;
}

.hover-preview {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
</style>

<style lang="scss">
.hover-image-popover {
  &.b-popover {
    border: none !important;
    margin-left: 8px;
  }

  .popover-body {
    padding: 0;
    width: 400px;
  }
}
</style>

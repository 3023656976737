<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row>
        <b-col sm="8" md="6" xl="7">
          <div class="font-weight-bold ft-20 header-tablepage">
            RECEIPT MONITOR
          </div>
        </b-col>
        <b-col sm="4" md="6" xl="5" class="d-flex align-items-center">
          <InputSelect
            name="type"
            valueField="id"
            textField="name"
            v-model="filter.id"
            :options="campaignList"
            @onDataChange="handleSelectCampaign"
            class="mb-0"
            style="flex: 1"
          >
          </InputSelect>
          <button
            @click="$router.push(`${filter.id}/create/0`)"
            type="button"
            class="btn button btn-mobile ml-2"
          >
            <font-awesome-icon icon="plus" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="plus-square" class="pointer" />
              <span class="btn-text ml-2">Create New</span>
            </span>
          </button>
        </b-col>
      </b-row>

      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-tabs>
          <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            @click="filterStatus(tab.id)"
          >
            <template #title>
              <strong
                >{{ tab.name }}
                <span v-if="tab.id == 1"> ({{ inProgessCount }}) </span>
                <span v-else-if="tab.id == 2">
                  ({{ waitForAcceptCount }})
                </span>
              </strong>
            </template>
            <ListPanel
              :columns="columns"
              :list="items"
              :isBusy="isBusy"
              :id="id"
              :tabIndex="tab.id"
              @getList="getList"
            />
            <Pagination
              :pageOptions="pageOptions"
              :filter="filter"
              :rows="rows"
              @pagination="pagination"
              @handleChangeTake="handleChangeTake"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ListPanel from "@/components/receipt/ListPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import Pagination from "@/components/Pagination";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    ListPanel,
    OtherLoading,
    Pagination,
    InputSelect,
  },
  data() {
    return {
      items: [],
      filter: {
        search: null,
        take: 10,
        page: 1,
        status: 0,
        id: this.$route.params.id,
      },
      id: this.$route.params.id,
      isLoading: true,
      rows: 0,
      showing: 1,
      showingTo: 10,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      isBusy: false,
      columns: [
        {
          label: "Receipt Image",
          key: "image_url",
          thStyle: { width: "15%" },
          tdClass: "p-3",
        },
        {
          label: "Transaction No.",
          key: "allow_customer_redeem",
        },
        {
          label: "Customer",
          key: "name",
        },
        {
          label: "Campaign Name",
          key: "receipt_name",
        },
        {
          label: "Created Time",
          key: "created_time",
        },
        {
          label: "Status",
          key: "status",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      tabs: [{ id: 0, name: "ทั้งหมด" }],
      statusList: [{ id: 0, name: "ทั้งหมด" }],
      tabIndex: 0,
      inProgessCount: 0,
      waitForAcceptCount: 0,
      campaignList: [],
    };
  },
  async created() {
    await this.getStatusList();
    await this.getCampaignList();
    await this.getList();
  },
  methods: {
    handleSelectCampaign(value) {
      this.$router.replace(`/setting/receipt/monitor/${value}`);
      this.filter = {
        ...this.filter,
        id: value,
        page: 1,
      };

      this.getList();
    },
    async getCampaignList() {
      await this.$store.dispatch("getReceiptScanNameList");
      let data = this.$store.state.receiptscan.receiptScannerNameList;
      if (data.result == 1) {
        this.campaignList = data.detail;
      }
    },
    async getList() {
      let filter = this.filter;
      this.isBusy = true;
      await this.$store.dispatch("getReceiptScanMonitorList", filter);
      let data = await this.$store.state.receiptscan.receiptScanMonitorList;
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.data_count;
        this.isBusy = false;
        this.showingTo =
          this.items?.length >= 10 ? this.showingTo : this.items.length;
        this.inProgessCount = data.detail.in_progess_count;
        this.waitForAcceptCount = data.detail.wait_for_accept_count;
        this.isLoading = false;
      } else {
        this.errorAlert();
      }
    },
    async getStatusList() {
      let statusList = [{ id: 0, name: "ทั้งหมด" }];
      await this.$store.dispatch("getReceiptScanStatusList");
      let data = await this.$store.state.receiptscan.receiptScanStatusList;
      if (data.result == 1) {
        this.statusList = statusList.concat(data.detail);
        this.tabs = this.statusList;
      }
    },
    searchList() {
      this.filter.page = 1;
      this.getList();
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getList();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show(true);
    },
    filterSearch(value) {
      this.filter = value;
      this.getList();
    },
    filterStatus(value) {
      this.filter.status = value;
      this.filter.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.alert-error {
  text-align: center;
  padding: 20px;
  color: #000;
}
::v-deep .custom-select {
  @media (max-width: 767px) {
    width: 100%;
  }
}
::v-deep .header-tablepage {
  font-weight: 600;
}
.btn-create {
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  margin: 15px 0;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .nav.nav-tabs {
  background-color: white;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
::v-deep .nav-tabs {
  border: none;
}
::v-deep .nav-tabs .nav-link {
  border: none;
  border-right: 1px solid #cfcfcf;
}
::v-deep .nav-tabs .nav-link {
  border-color: #d8dbe0 !important;
  color: #717171 !important;
}
::v-deep .nameOfTheClass {
  max-width: 250px !important;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .width-td-img {
  width: 200px !important;
}
</style>
